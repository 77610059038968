/*
 * @Date: 2023-08-14 09:14:45
 * @LastEditors: dzj
 * @LastEditTime: 2023-08-21 15:06:14
 * @FilePath: \equick-project\src\stores\index.js
 * @discribe: 
 */
import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
  state: () => {
    return {
      aboutNav: 0,
      commonData: {},
      queryParams: '',
    }
  },
  getters: {
    getAboutNav: (state) => state.aboutNav,
    getCommonData: (state) => state.commonData,
    getQueryParams: (state) => state.queryParams,
  },
  actions: {
    setAboutNav(v) {
      this.aboutNav = v
    },
    setCommonData(v) {
      this.commonData = v
    },
    setQueryParams(v) {
      this.queryParams = v
    },
  }
})