/*
 * @Author: zjdeng
 * @Date: 2023-08-06 17:54:27
 * @LastEditors: dzj
 * @LastEditTime: 2023-08-21 16:42:08
 * @FileDescription: 
 */
import axios from 'axios'

let getlType = () => {
  return localStorage.getItem('language') === 'en' ? 'EN' : 'CN'
}

let request = axios.create({ //请求统一实例
  baseURL: process.env.VUE_APP_HTTP_URL,
  timeout: 120000
});

// 异常拦截处理器
const errorHandler = (error) => {
  // message.error(error.message)
  return Promise.reject(error)
}

request.interceptors.request.use((config) => {
  if (config.method === 'get') {
    if (config.params) {
      config.params.lType = getlType()
    } else {
      config.params = {
        lType: getlType()
      }
    }
  }
  if (config.postType !== 'json' && config.responseType === "arraybuffer" && config.method === 'post') {
    
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

request.interceptors.response.use(function(response) {
  if (response.status === 200) return response.data
  return response
}, errorHandler);

export { request }