/*
 * @Author: zjdeng
 * @Date: 2023-08-06 13:09:18
 * @LastEditors: zjdeng
 * @LastEditTime: 2023-08-06 17:46:21
 * @FileDescription: 
 */
import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import zh from './langs/zh'
import en from './langs/en'

const language = localStorage.getItem('language') || 'zh'
const i18n = createI18n({
  fallbackLocale: 'zh',
  globalInjection:true,
  legacy: false, // you must specify 'legacy: false' option
  // locale: language.split("-")[0] || "zh",//默认显示的语言 
  locale: language,
  messages: {
    zh,
    en 
  },//本地化的语言环境信息。
});

export default i18n