/*
 * @Date: 2023-08-16 14:34:42
 * @LastEditors: dzj
 * @LastEditTime: 2023-08-16 16:25:40
 * @FilePath: \equick-project\src\api\home.js
 * @discribe: 
 */
import { request } from '@/utils/axios'

// 通用数据
export function getCommonData() {
  return request({
    url: '/equick/ehomepageapi/getcommon',
    method: "get",
  });
}


// 获取首页信息
export function getHomePage() {
  return request({
    url: '/equick/ehomepageapi/getehomepage',
    method: "get",
  });
}

// 获取SEO设置数据
export function getSEOData() {
  return request({
    url: '/equick/ehomepageapi/seo',
    method: "get",
  });
}
