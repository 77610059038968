/*
 * @Author: zjdeng
 * @Date: 2023-08-06 13:09:18
 * @LastEditors: zjdeng
 * @LastEditTime: 2023-10-07 13:37:18
 * @FileDescription: 
 */
export default {
	navName: {
		homeName: 'HOME',
		productName: 'PRODUCT SERVICES',
		product1Name: 'SEMI-TRACK',
		product2Name: 'FULL TRACK STANDARD',
		product3Name: 'FULL TRACK EXPRESS',
		product4Name: 'COD PRODUCTS',

		supportName: 'SUPPORT',
		networkQuery: 'Address Query',
		waybillInquiry: 'Package Tracking',
		newsName: 'NEWS',
		aboutName: 'ABOUT US',
		loginName: 'LOGIN',
	},
	homeSearch: {
		btn1: 'Shipping',
		btn3: 'Get A Quote',
		btn4: 'Contact Us',
		placeholder: 'Enter The Tracking Number'
	},
	homePageTitle: {
		Channels: 'Premium Channels',
		product: 'Product Services',
		news: 'News Informations',
		partner: 'Cooperative Vendors',
		eCommerce: 'E-commerce Platform',
	},
	productPageTitle: {
		fullCheck: 'FULL TRACK STANDARD',
		fullCheckSpeed: 'FULL TRACK EXPRESS',
		halfCheck: 'SEMI-TRACK',
		COD: 'COD PRODUCTS',
		process: 'Service Process',
	},
	productProcessTitle: {
		step1: 'Pick-up',
		step2: 'Operation In Warehouse',
		step3: 'Export Customs Declaration',
		step4: 'Air Linehaul',
		step5: 'Customs Clearance',
		step6: 'Last Mile Delivery',
		step7: 'After-sales Service',
	},
	productDetail: {
		introduction: 'Product Introduction',
		size: 'Size Requirements',
		weight: 'Weight Requirement',
		service: 'Service Description',
		compensation: 'Compensation Standard',
		queryMode: 'Query Mode',
	},
	supportNetTitle: {
		contactName: 'CONTACT US', // 没用到
		address: 'Office Address',
		phone: 'Telephone',
		OperationAddress: 'Operation Center Address',
	},
	footer: {
		contactName: 'CONTACT US',
		contactName2: 'Contact Us',
		onlineMessage: 'Online Message',
		email: 'Official Email',
		partnerEmail: 'Cooperation Email',
		tel: 'Tel',
		attentionName: 'FOLLOW US',
		copyright: '2017 Beijing NetEase Express International Freight Forwarding Co., LTD. All rights reserved Beijing Public network security No. 11010502036737 record/License number: Beijing ICP No. 09064397-1',
		copyright1: '2017 Beijing NetEase Suda International Freight Forwarding Co., LTD. All rights reserved',
		copyright2: 'Beijing Public Network Anbei 11010502036737',
		copyright3: 'Record/License number: Beijing ICP No. 09064397-1',
	},
	supportWay: {
		searchTitle: 'Track Your Shipment',
		placeholder: 'Enter your tracking number separated by ENTER',
		searchBtnText: 'Search',
		searchRuleText: 'One for each line. A maximum of 50 order numbers can be queried at a time',
		trackingNumber: 'Tracking Number',
		foreignTrackingNumber: 'Foreign Tracking Number',
		location: 'Location',
		currentStatus: 'Current Status',
		trackingTime: 'Tracking Time',
		locations: 'Locations',
		country: 'Destination Country',
		traceContent: 'Tracking Content',
		status: 'Status',
		emptyTitle: 'No logistics Information Available',
	},
	news: {
		tile: 'News Center',
		menu1: 'Important Announcement',
		menu2: 'News Trends',
		menu3: 'Industry Tnformation',
	},
	about: {
		title: 'About Us',
		menu1: 'Contact Us',
		menu2: 'Talent Recruitment',
		menu3: 'Customer Service',
		company: 'Company Profile',
		contact: {
			phone: 'Customer Service Hotline',
			date: 'Service Time',
		},
		recruitment: {
			contactPerson: 'Contact Person',
			email: 'Email',
			location: 'Location',
			recruitmentPosition: 'Recruitment Position',
		},
		inquiryTitle: `Fill out the form and we will arrange dedicated customer service for you. For logistics inquiries, please email: cscn{'@'}equick.cn. We will reply to you promptly!`,
		inquiry: {
			name: 'Customer Name',
			phone: 'Telephone',
			email: 'Email',
			city: 'City',
			type: 'Business Type',
			other: 'Other Needs',
			submitBtn: 'Submit',
		},
		successInfo: 'Success',
		errorInfo: 'Defeated',
		phoneErrorInfo: 'Please Enter The Phone Number',
		cnameErrorInfo: 'Please Enter Name',
		cityErrorInfo: 'Please Enter City',
		typesErrorInfo: 'Please Select Type',
	},
	other: {
		more: 'More',
		seeMore: 'See More',
		close: 'Close',
		customerService: 'Customer Service',
		customerMessage: 'Customer Message',
	}
}