/*
 * @Author: zjdeng
 * @Date: 2023-08-06 13:09:18
 * @LastEditors: zjdeng
 * @LastEditTime: 2023-08-06 17:09:45
 * @FileDescription: 
 */
import { createRouter, createWebHashHistory } from 'vue-router'

export const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import( `@/views/home` ),
  },
  {
    path: '/product',
    name: 'product',
    component: () => import( `@/views/product` ),
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import( `@/views/product/detail` ),
  },
  {
    path: '/support',
    name: 'support',
    component: () => import( `@/views/support` ),
    children: [
      {
        path: 'supportNet',
        name: 'supportNet',
        component: () => import( `@/views/support/supportNet.vue` ),
      },
      {
        path: 'supportWay',
        name: 'supportWay',
        component: () => import( `@/views/support/supportWay.vue` ),
      },
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( `@/views/news` ),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( `@/views/about` ),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
});
export default router;