/*
 * @Author: zjdeng
 * @Date: 2023-08-06 13:09:18
 * @LastEditors: dzj
 * @LastEditTime: 2023-08-21 15:53:53
 * @FileDescription: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {
  ConfigProvider,
  Pagination,
  Radio,
  Menu,
  Carousel,
  Input,
  InputSearch,
  Row,
  Col,
  List,
  Breadcrumb,
  Form,
  Checkbox,
  Empty,
} from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
// import '@/assets/css/common.less'
import i18n  from '@/i18n/i18n'
import { createPinia } from 'pinia'

const app = createApp(App)
app.use(ConfigProvider)
app.use(Pagination)
app.use(Radio)
app.use(Menu)
app.use(Carousel)
app.use(Input)
app.use(InputSearch)
app.use(Row)
app.use(Col)
app.use(List)
app.use(Breadcrumb)
app.use(Form)
app.use(Checkbox)
app.use(Empty)
app.use(i18n)
app.use(createPinia())
app.use(router).mount('#app')
