/*
 * @Author: zjdeng
 * @Date: 2023-08-12 19:38:38
 * @LastEditors: zjdeng
 * @LastEditTime: 2023-10-07 15:08:19
 * @FileDescription: 
 */
export default {
	navName: {
		homeName: '首页',
		productName: '产品服务',
		product1Name: '半查专线',
		product2Name: '全查专线',
		product3Name: '全查快速专线',
		product4Name: 'COD专线产品',

		supportName: '服务支持',
		networkQuery: '网点查询',
		waybillInquiry: '运单查询',
		newsName: '新闻资讯',
		aboutName: '关于我们',
		loginName: '登录',
	},
	homeSearch: {
		btn1: '立即发货',
		btn3: '获得报价',
		btn4: '联系我们',
		placeholder: '输入运单号查询物流轨迹',
	},
	homePageTitle: {
		Channels: '优质渠道',
		product: '产品服务',
		news: '新闻资讯',
		partner: '合作伙伴',
		eCommerce: '电商平台',
	},
	productPageTitle: {
		fullCheck: '全查专线',
		fullCheckSpeed: '全查快速专线',
		halfCheck: '半查专线',
		COD: 'COD代收货款专线产品',
		process: '服务流程',
	},
	productProcessTitle: {
		step1: '揽收',
		step2: '库内操作',
		step3: '出口报关',
		step4: '专线运输',
		step5: '目的国清关',
		step6: '尾程派送',
		step7: '售后服务',
	},
	productDetail: {
		introduction: '产品介绍',
		size: '尺寸要求',
		weight: '重量要求',
		service: '服务说明',
		compensation: '赔偿标准',
		queryMode: '查询方式',
	},
	supportNetTitle: {
		contactName: '联系我们',
		address: '办公地址',
		phone: '电话',
		OperationAddress: '操作中心',
	},
	footer: {
		contactName: '联系我们',
		contactName2: '马上联系我们',
		onlineMessage: '在线留言',
		email: '官方邮箱',
		partnerEmail: '市场合作',
		tel: '服务热线',
		attentionName: '马上关注我们',
		copyright: '2017北京网易速达国际货运代理有限公司版权所有 京公网安备11010502036737号 备案/许可证号: 京ICP备09064397号-1',
		copyright1: '2017北京网易速达国际货运代理有限公司版权所有',
		copyright2: '京公网安备11010502036737号',
		copyright3: '备案/许可证号: 京ICP备09064397号-1',
	},
	supportWay: {
		searchTitle: '追踪您的货件',
		placeholder: '输入您的运单号，多个运单号以Enter隔开',
		searchBtnText: '查询',
		searchRuleText: '每行一个，一次可以最多查询50个单号',
		copyText:'复制链接',
		trackingNumber: '跟踪单号',
		foreignTrackingNumber: '国外单号',
		location: '当前位置',
		currentStatus: '当前状态',
		trackingTime: '追踪时间',
		locations: '位置',
		country: '目的地国家',
		traceContent: '跟踪内容',
		status: '状态',
		emptyTitle: '暂无物流信息',
	},
	news: {
		tile: '新闻中心',
		menu1: '重要公告',
		menu2: '新闻动态',
		menu3: '行业资讯',
	},
	about: {
		title: '关于我们',
		menu1: '联系我们',
		menu2: '人才招聘',
		menu3: '新客户咨询',
		company: '公司简介',
		contact: {
			phone: '客服热线',
			date: '服务时间',
		},
		recruitment: {
			contactPerson: '联系人',
			email: '邮箱',
			location: '地址',
			recruitmentPosition: '招聘岗位',
		},
		inquiryTitle: `填写表单，我们将安排专属客服为您服务。咨询包裹问题查询请邮件至：cscn{'@'}equick.cn，客服将及时给您回复！`,
		inquiry: {
			name: '客户名称',
			phone: '联系电话',
			email: '联系邮箱',
			city: '所在城市',
			type: '业务类型',
			other: '其他需求',
			submitBtn: '提交',
		},
		successInfo: '提交成功',
		errorInfo: '提交失败',
		phoneErrorInfo: '请输入电话',
		cnameErrorInfo: '请输入名称',
		cityErrorInfo: '请输入城市',
		typesErrorInfo: '请选择业务类型',
	},
	other: {
		more: '更多',
		seeMore: '查看更多',
		close: '关闭',
		customerService: '在线客服',
		customerMessage: '客户留言'
	}
}