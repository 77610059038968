<!--
 * @Author: zjdeng
 * @Date: 2023-08-06 13:09:18
 * @LastEditors: zjdeng
 * @LastEditTime: 2023-12-13 15:24:56
 * @FileDescription: 
-->
<template>
  <a-config-provider
    :auto-insert-space-in-button="false"
    :locale="antdLocale === 'en' ? enUS : zhCN"
  >
    <div>
      <header>
        <div class="header-content equick-content">
          <router-link to="/home"><div class="equick-logo"></div></router-link>
          <div class="app-nav">
            <a-menu
              v-model:selectedKeys="navActive"
              mode="horizontal"
              @click="clickMenu"
            >
              <!-- 首页 -->
              <a-menu-item key="home" class="nav-item">
                <router-link to="/home">{{
                  $t("navName.homeName")
                }}</router-link>
              </a-menu-item>
              <!-- 产品服务 -->
              <a-menu-item
                key="product"
                class="nav-item nav-subItem productNav"
              >
                <router-link to="/product"
                  >{{ $t("navName.productName") }}<DownOutlined
                /></router-link>
                <ul class="product-list">
                  <li @click="gotoProduct(3)">
                    {{ $t("navName.product1Name") }}
                  </li>
                  <li @click="gotoProduct(1)">
                    {{ $t("navName.product2Name") }}
                  </li>
                  <li @click="gotoProduct(2)">
                    {{ $t("navName.product3Name") }}
                  </li>
                  <li @click="gotoProduct(4)">
                    {{ $t("navName.product4Name") }}
                  </li>
                </ul>
              </a-menu-item>
              <!-- 服务支持 -->
              <!-- <a-menu-item key="support" class="nav-item">
                <router-link to="/support">{{ $t('navName.supportName') }}</router-link>
              </a-menu-item> -->
              <a-sub-menu
                key="support"
                class="nav-item"
                popupClassName="antd-nav-subItem"
              >
                <template #title>
                  {{ $t("navName.supportName") }}<DownOutlined />
                </template>
                <a-menu-item key="supportWay">
                  <router-link :to="{ name: 'supportWay' }">{{
                    $t("navName.waybillInquiry")
                  }}</router-link>
                </a-menu-item>
                <a-menu-item key="supportNet">
                  <router-link :to="{ name: 'supportNet' }">{{
                    $t("navName.networkQuery")
                  }}</router-link>
                </a-menu-item>
              </a-sub-menu>
              <!-- 新闻中心 -->
              <a-menu-item key="news" class="nav-item">
                <router-link to="/news">{{
                  $t("navName.newsName")
                }}</router-link>
              </a-menu-item>
              <!-- 关于我们 -->
              <!-- <a-menu-item key="about" class="nav-item"><router-link to="/about">{{ $t('navName.aboutName') }}</router-link></a-menu-item> -->
              <a-menu-item key="about" class="nav-item nav-subItem">
                <router-link to="/about"
                  >{{ $t("navName.aboutName") }}<DownOutlined
                /></router-link>
                <ul class="product-list about-list">
                  <li @click="gotoAbout(1)">{{ $t("about.menu1") }}</li>
                  <li @click="gotoAbout(2)">{{ $t("about.menu2") }}</li>
                  <li @click="gotoAbout(3)">{{ $t("about.menu3") }}</li>
                </ul>
              </a-menu-item>
            </a-menu>
          </div>
          <div class="equick-lang" @click="changeLang">
            <span :class="{ 'lang-active': antdLocale === 'en' }">EN</span> |
            <span :class="{ 'lang-active': antdLocale !== 'en' }">CN</span>
          </div>
          <!-- 登录 -->
          <a class="loginBtn" href="http://ecs.equick.cn/" target="_blank">{{
            $t("navName.loginName")
          }}</a>
        </div>
      </header>
      <div class="app-content">
        <router-view></router-view>
      </div>
      <footer>
        <div class="footer-body">
          <div class="equick-content footer-content">
            <div>
              <h3>{{ $t("navName.productName") }}</h3>
              <p class="link" @click="gotoProduct(3)">
                {{ $t("navName.product1Name") }}
              </p>
              <p class="link" @click="gotoProduct(1)">
                {{ $t("navName.product2Name") }}
              </p>
              <p class="link" @click="gotoProduct(2)">
                {{ $t("navName.product3Name") }}
              </p>
              <p class="link" @click="gotoProduct(4)">
                {{ $t("navName.product4Name") }}
              </p>
            </div>
            <div>
              <h3>{{ $t("navName.supportName") }}</h3>
              <!-- <p>{{ $t('navName.waybillInquiry') }}</p>
              <p>{{ $t('navName.networkQuery') }}</p> -->
              <p>
                <router-link :to="{ name: 'supportWay' }">{{
                  $t("navName.waybillInquiry")
                }}</router-link>
              </p>
              <p>
                <router-link :to="{ name: 'supportNet' }">{{
                  $t("navName.networkQuery")
                }}</router-link>
              </p>
            </div>
            <div>
              <h3>{{ $t("footer.contactName") }}</h3>
              <p class="link" @click="goOnlineMessage">
                {{ $t("footer.onlineMessage") }}
              </p>
              <p>{{ $t("footer.email") }}: {{ commonData?.official?.email }}</p>
              <p>
                {{ $t("footer.partnerEmail") }}:
                {{ commonData?.official?.cooperation }}
              </p>
              <p>{{ $t("footer.tel") }}: {{ commonData?.official?.hotline }}</p>
            </div>
            <div>
              <h3>{{ $t("footer.attentionName") }}:</h3>
              <div class="footer-icon">
                <div class="footer-icon-item">
                  <i class="wx"></i>
                  <div class="footer-icon-popover">
                    <img :src="imgPath + commonData.wxImage" alt="" />
                  </div>
                </div>
                <div class="footer-icon-item">
                  <i class="wb"></i>
                  <div class="footer-icon-popover">
                    <img :src="imgPath + commonData.wbImage" alt="" />
                  </div>
                </div>
                <div class="footer-icon-item">
                  <i class="dy"></i>
                  <div class="footer-icon-popover">
                    <img :src="imgPath + commonData.dyImage" alt="" />
                  </div>
                </div>
                <div class="footer-icon-item">
                  <i class="zh"></i>
                  <div class="footer-icon-popover">
                    <img :src="imgPath + commonData.zhImage" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">{{ $t("footer.copyright") }}</div>
      </footer>
      <div class="customer-service">
        <div class="customer-service-item" @click="goOnlineService">
          <i class="icon-customer-service"></i>
          <p :class="{ en: antdLocale === 'en' }">
            {{ $t("other.customerService") }}
          </p>
        </div>
        <div class="customer-service-item" @click="goOnlineMessage">
          <i class="icon-customer-message"></i>
          <p :class="{ en: antdLocale === 'en' }">
            {{ $t("other.customerMessage") }}
          </p>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script setup>
// useI18n()
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { useI18n } from "vue-i18n";
import { DownOutlined } from "@ant-design/icons-vue";
import { useStore } from "@/stores";
import { getCommonData, getSEOData } from "@/api/home";
const imgPath = process.env.VUE_APP_IMG_URL;
const language = localStorage.getItem("language");
// 自定义文字语言-otherLocale
const { locale } = useI18n();
// antd组建语言-locale
const antdLocale = ref(language);
// 导航
const router = useRouter();
const route = useRoute();
const store = useStore();
const commonData = ref({});
let navActive = ref([route.name]);
const changeLang = function () {
  if (antdLocale.value === "en") {
    antdLocale.value = "zh";
    locale.value = "zh";
    localStorage.setItem("language", "zh");
  } else {
    antdLocale.value = "en";
    locale.value = "en";
    localStorage.setItem("language", "en");
  }
  location.reload();
};

const clickMenu = ({ item, key, keyPath }) => {
  // if (key === 'about') {
  //   store.setAboutNav(0)
  // }
};
// 跳转产品服务
const gotoProduct = (type) => {
  router.push({ name: "product", query: { type: type } });
};
// 跳转新客户咨询
const goOnlineMessage = () => {
  // store.setAboutNav(3)
  router.push({ name: "about", query: { type: 3 } });
};
// 跳转在线客服
const goOnlineService = () => {
  window.open("https://work.weixin.qq.com/kfid/kfc862d1a5d466edf3e");
};
// 跳转关于我们
const gotoAbout = (type) => {
  // store.setAboutNav(type)
  router.push({ name: "about", query: { type: type } });
};

onMounted(() => {
  getCommonData().then((res) => {
    if (res.code === 200) {
      const data = res.data;
      commonData.value = data;
      store.setCommonData(data);
    }
  });
  getSEOData().then((res) => {
    if (res.code === 200) {
      const data = res.data;
      document.title = data.seoTitle;
      let head = document.getElementsByTagName("head");
      let meta_keyword = document.createElement("meta");
      if (document.querySelector('meta[name="keywords"]')) {
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", data.seoKeywords);
      } else {
        meta_keyword.setAttribute("name", "keywords");
        meta_keyword.setAttribute("content", data.seoKeywords);
        head[0].appendChild(meta_keyword);
      }

      let meta_description = document.createElement("meta");
      if (document.querySelector('meta[name="description"]')) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", data.seoDescription);
      } else {
        meta_description.setAttribute("name", "description");
        meta_description.setAttribute("content", data.seoDescription);
        head[0].appendChild(meta_description);
      }
    }
  });
});

watch(
  () => route.path,
  (val) => {
    const navArr = val.substring(1).split("/");
    navActive.value = navArr;
    document.getElementById("app").scrollTop = 0;
  },
  { deep: true }
);
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
  header {
    height: 78px;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    .header-content {
      height: 100%;
      line-height: 78px;
      display: flex;
      align-items: center;
    }
    .equick-logo {
      background-image: url("@/assets/equick-logo.png");
      width: 116px;
      height: 30px;
    }
    .app-nav {
      height: 100%;
      margin-left: 65px;
      .nav-item {
        width: 110px;
        &.productNav {
          width: 150px;
        }
        .anticon {
          font-size: 10px;
        }
      }
      .nav-subItem {
        width: 116px;
        .product-list {
          background-color: #fff;
          width: 200px;
          height: 152px;
          opacity: 0.8;
          border-radius: 6px;
          overflow: hidden;
          position: absolute;
          top: 79px;
          left: 0;
          z-index: 1;
          display: none;
          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
            0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 9px 28px 8px rgba(0, 0, 0, 0.05);
          li {
            height: 38px;
            line-height: 38px;
            text-align: left;
            padding-left: 15px;
            &:hover {
              background-color: #e6e6e6;
            }
          }
        }
        .about-list {
          // width: 170px;
          height: 114px;
        }
        &:hover .product-list {
          display: block;
        }
      }
      .ant-menu .ant-menu-submenu .ant-menu-submenu-title .anticon {
        font-size: 10px;
      }
      .ant-menu-light.ant-menu-horizontal {
        height: 78px;
        line-height: 74px;
        border: none;
        text-align: center;
        & > .ant-menu-item:hover::after,
        & > .ant-menu-submenu:hover::after,
        & > .ant-menu-item-selected::after,
        & > .ant-menu-submenu-selected::after,
        & > .ant-menu-submenu-active::after,
        & > .ant-menu-item-active::after {
          border-bottom-width: 3px;
          border-bottom-color: rgba(167, 7, 7, 1);
        }
        & > .ant-menu-item-selected,
        & > .ant-menu-submenu-selected,
        & > .ant-menu-submenu-selected > .ant-menu-submenu-title {
          color: #222;
        }
      }
    }
    .equick-lang {
      cursor: pointer;
      font-size: 16px;
      margin-left: 20px;
      .lang-active {
        color: @primary-color;
      }
    }
    .loginBtn {
      margin-left: 36px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .app-content {
    min-height: 300px;
    margin-top: 78px;
  }
  footer {
    height: 321px;
    color: #fff;
    .footer-body {
      background-color: rgba(66, 68, 72, 1);
      height: 271px;
      .footer-content {
        display: flex;
        justify-content: space-around;
        & > div {
          padding-top: 50px;
          h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
          }
          p {
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            margin-bottom: 15px;
            &.link {
              cursor: pointer;
            }
            a {
              color: rgba(255, 255, 255, 0.8);
              font-size: 16px;
              text-decoration-line: none;
            }
          }
        }
        .footer-icon {
          margin-top: 30px;
          height: 30px;
          .footer-icon-item {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 16px;
            position: relative;
            i {
              background-size: 30px 30px;
              border-radius: 15px;
              display: inline-block;
              width: 30px;
              height: 30px;
            }
            .wx {
              background-image: url("@/assets/img/wx.png");
            }
            .wb {
              background-image: url("@/assets/img/wb.png");
            }
            .dy {
              background-image: url("@/assets/img/dy.png");
            }
            .zh {
              background-image: url("@/assets/img/zh.png");
            }

            .footer-icon-popover {
              background-image: url("@/assets/img/qrCode-bg.png");
              background-size: 100% 100%;
              width: 180px;
              height: 218px;
              text-align: center;
              padding-top: 42px;
              position: absolute;
              bottom: 10px;
              left: -75px;
              display: none;
              img {
                width: 91px;
                height: 91px;
              }
            }
            &:hover {
              .footer-icon-popover {
                display: block;
              }
            }
          }
        }
      }
    }
    .copyright {
      background-color: rgba(167, 7, 7, 1);
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      text-align: center;
    }
  }
  .customer-service {
    background-color: @primary-color;
    border-radius: 8px;
    color: #fff;
    width: 88px;
    height: 270px;
    position: fixed;
    right: 50px;
    bottom: 100px;
    padding-top: 24px;
    .customer-service-item {
      text-align: center;
      margin-bottom: 5px;
      cursor: pointer;
      i {
        background-size: 100% 100%;
        display: inline-block;
        width: 29px;
        height: 29px;
        margin-bottom: 10px;
      }
      .icon-customer-service {
        background-image: url("@/assets/icon/icon-customer-service.png");
      }
      .icon-customer-message {
        background-image: url("@/assets/icon/icon-customer-message.png");
        height: 27px;
      }
      p {
        display: inline-block;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 10px;
        padding-left: 8px;
      }
      p.en {
        letter-spacing: 0px;
        font-size: 16px;
      }
    }
  }
}
.antd-nav-subItem.ant-menu-submenu-popup {
  opacity: 0.8;
  border-radius: 0;
  .ant-menu-vertical .ant-menu-item {
    margin: 0;
    width: 100%;
  }
  &.ant-menu-light .ant-menu-item-selected {
    color: #222;
    background-color: #e6e6e6;
  }
  .ant-menu-submenu > .ant-menu {
    border-radius: 0;
  }
}
</style>
